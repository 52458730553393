import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueMatomo from 'vue-matomo'

import ElementPlus from 'element-plus'
import "../public/custom/index.css"
import * as echarts from 'echarts'
import 'vue-simple-uploader/dist/style.css'
import { createStore } from 'vuex'
import AOS from 'aos'
import Lazyload from "vue3-lazyload";
import 'aos/dist/aos.css'
AOS.init()


// 引入第三方css文件
import '../public/lib/bootstrap/css/bootstrap.min.css'
import '../public/lib/font-awesome/css/font-awesome.min.css'
import '../public/lib/animate/animate.min.css'
import '../public/lib/ionicons/css/ionicons.min.css'

import '../public/lib/owlcarousel/assets/owl.carousel.min.css'
import '../public/lib/lightbox/css/lightbox.min.css'
import '../public/css/style.css'


const app = createApp(App)
const store = createStore({
    state() {
        return {
            login: false
        }
    },
    mutations: {
        changeLogin(state) {
            state.login = true
        }
    }
})
app.config.globalProperties.$echarts = echarts;
app.use(store).use(router).use(ElementPlus).use(Lazyload).use(VueMatomo, {
    // Configure your Matomo server and site by providing:
    host: 'https://analytics.phenonet.org/',
    siteId: 1,
}).mount('#app')

window._paq.push(['trackPageView']); // To track a page view